import React, { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby-theme-material-ui"
import classnames from "classnames"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import LogoHorizontal from "../../images/logo_horizontal"
import Button from "../button"
import SocialLinks from "../socialLinks"

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "1.5rem 2rem",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: "3rem",
    },

    [theme.breakpoints.up("lg")]: {
      padding: "3rem 4rem",
    },
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",

    "& > a": {
      marginLeft: "2rem",
      color: theme.palette.primary.textColor,

      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
  },
  logoLink: {
    display: "inline-block",

    [theme.breakpoints.up("sm")]: {
      margin: 0,
    },
  },
  logo: {
    width: 125,

    [theme.breakpoints.up("sm")]: {
      width: 175,
    },
  },
  pageHeading: {
    margin: "0 auto",
    maxWidth: 750,
    padding: "3rem 2.5rem 2rem",
    textAlign: "center",
  },
  freeTrailButton: {
    margin: "2rem 0 1rem",
  },
  hero: {
    background: `linear-gradient(180deg, ${theme.palette.background.default} 70%, transparent 70%)`,
    padding: "0",
    textAlign: "center",
    minHeight: 850,

    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(180deg, ${theme.palette.background.default} 55%, transparent 55%)`,
      padding: "0 0 5rem",
    },

    [theme.breakpoints.up("md")]: {
      background: `linear-gradient(180deg, ${theme.palette.background.default} 53%, transparent 53%)`,
    },

    "& img": {
      margin: "0 auto",
    },
  },
  content: {
    backgroundColor: theme.palette.primary.white,
    padding: "0 4rem 8rem",
  },
  contentShiftUp: {
    marginTop: "-75px",
    padding: "0 1rem 4rem",

    [theme.breakpoints.up("sm")]: {
      marginTop: "-270px",
      padding: "0 2rem 4rem",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "-300px",
    },
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  bottomFooter: {
    borderTop: `1px solid ${theme.palette.primary.white}`,
    color: theme.palette.primary.white,
    margin: "0 1rem",
    padding: "1.2rem 0",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0 3rem",
    },
  },
  links: {
    marginTop: "1rem",

    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
      marginTop: 0,

      "& a:first-of-type": {
        marginRight: "1rem",
      },
    },
  },
  cta: {
    textAlign: "center",
    padding: "5rem 1rem",

    "& a": {
      marginTop: "1.5rem",
    },
  },
  contentWidthWrapper: {
    margin: "0 auto",
    maxWidth: "1400px",
  },
  heroImage: {
    maxWidth: "100%",
  },
})

const PublicLayout = props => {
  const {
    children,
    className,
    classes,
    headline,
    subheading,
    src,
    alt,
    authenticated,
    canonicalUrl,
    showFreeTrial = false,
    showSignup = true,
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className={classes.root}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className={src ? classes.hero : ""}>
        <div className={classes.contentWidthWrapper}>
          <div className={classes.header}>
            <Link className={classes.logoLink} to="/">
              <LogoHorizontal className={classes.logo} />
            </Link>

            <div className={classes.actions}>
              <Link to="/features">
                <Typography
                  variant="body1"
                  color="inherit"
                  onClick={() =>
                    trackCustomEvent({
                      category: "Home Page",
                      action: "Click",
                      label: "Features Main Nav",
                    })
                  }
                >
                  {"Features"}
                </Typography>
              </Link>
              <Link to="/solutions">
                <Typography
                  variant="body1"
                  color="inherit"
                  onClick={() =>
                    trackCustomEvent({
                      category: "Home Page",
                      action: "Click",
                      label: "Solutions Main Nav",
                    })
                  }
                >
                  {"Solutions"}
                </Typography>
              </Link>
              <Link to="/pricing">
                <Typography
                  variant="body1"
                  color="inherit"
                  onClick={() =>
                    trackCustomEvent({
                      category: "Home Page",
                      action: "Click",
                      label: "Pricing Main Nav",
                    })
                  }
                >
                  {"Pricing"}
                </Typography>
              </Link>
              <Link to="/contact-us">
                <Typography
                  variant="body1"
                  color="inherit"
                  onClick={() =>
                    trackCustomEvent({
                      category: "Home Page",
                      action: "Click",
                      label: "Contact Us Main Nav",
                    })
                  }
                >
                  {"Contact Us"}
                </Typography>
              </Link>
              {authenticated ? (
                <Link to="/dashboard">
                  <Typography
                    variant="body1"
                    color="inherit"
                    onClick={() =>
                      trackCustomEvent({
                        category: "Home Page",
                        action: "Click",
                        label: "Go To Dashboard Main Nav",
                      })
                    }
                  >
                    {"Go To Dashboard"}
                  </Typography>
                </Link>
              ) : (
                <Link to={`${process.env.GATSBY_APP_HOST}/`}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    onClick={() =>
                      trackCustomEvent({
                        category: "Home Page",
                        action: "Click",
                        label: "Sign In Main Nav",
                      })
                    }
                  >
                    {"Sign in"}
                  </Typography>
                </Link>
              )}
            </div>
          </div>
          <div className={classes.pageHeading}>
            <Typography variant={"h1"} gutterBottom>
              {headline}
            </Typography>
            <Typography variant={"subtitle2"}>{subheading}</Typography>
            {showSignup && (
              <Fragment>
                <Button
                  className={classes.freeTrailButton}
                  href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
                  onClick={() =>
                    trackCustomEvent({
                      category: "Home Page",
                      action: "Click",
                      label: "Signup Top",
                    })
                  }
                >
                  {"Start Free Trial"}
                </Button>
              </Fragment>
            )}
          </div>

          {src && <img className={classes.heroImage} src={src} alt={alt} />}
        </div>
      </div>
      <div
        className={classnames(
          classes.content,
          src ? classes.contentShiftUp : "",
          className
        )}
      >
        <div className={classes.contentWidthWrapper}>{children}</div>
      </div>
      <div className={classes.footer}>
        <div className={classes.contentWidthWrapper}>
          <div className={classes.cta}>
            <Typography variant="h2" color="textSecondary">
              Ready to get started?
            </Typography>
            <Button
              className={classes.freeTrailButton}
              color="dark"
              href={`${process.env.GATSBY_APP_HOST}/signup`}
              onClick={() =>
                trackCustomEvent({
                  category: "Home Page",
                  action: "Click",
                  label: "Signup Footer",
                })
              }
            >
              {"Start your business plan"}
            </Button>
            <Typography color="textSecondary" variant={"body2"}>
              {"Risk-free for 60 days. 100% refund if not 100% satisfied."}
            </Typography>
          </div>
          <SocialLinks />
          <div className={classes.bottomFooter}>
            <Typography color="textSecondary">
              {new Date().getFullYear()} LaunchPlan LLC. All rights reserved.
            </Typography>
            <div className={classes.links}>
              <Link
                to="/privacy-policy"
                onClick={() =>
                  trackCustomEvent({
                    category: "Home Page",
                    action: "Click",
                    label: "Privacy Policy",
                  })
                }
              >
                <Typography color="textSecondary">
                  {"Privacy Policy"}
                </Typography>
              </Link>
              <Link
                to="/terms-of-service"
                onClick={() =>
                  trackCustomEvent({
                    category: "Home Page",
                    action: "Click",
                    label: "Terms Of Service",
                  })
                }
              >
                <Typography color="textSecondary">
                  {"Terms of Service"}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withStyles(styles)(PublicLayout)

import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import Button from "../components/button"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const styles = theme => ({
  root: {
    padding: "2rem 1rem 3rem",
    display: "flex",
    justifyContent: "center",

    "& a": {
      margin: "0 0.5rem",
    },
  },
})

const SocialLinks = props => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Button
        variant="icon"
        component={"a"}
        target="_blank"
        href="https://www.instagram.com/launchplan/"
        rel="noreferrer"
        onClick={() =>
          trackCustomEvent({
            category: "Outgoing Link",
            action: "Instagram",
          })
        }
      >
        <InstagramIcon />
        <Typography variant="srOnly">Instagram</Typography>
      </Button>
      <Button
        variant="icon"
        component={"a"}
        target="_blank"
        href="https://www.facebook.com/launchplan/"
        rel="noreferrer"
        onClick={() =>
          trackCustomEvent({
            category: "Outgoing Link",
            action: "Facebook",
          })
        }
      >
        <FacebookIcon />
        <Typography variant="srOnly">Facebook</Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(SocialLinks)

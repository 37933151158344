import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import { ButtonBase } from "@material-ui/core"

export const styles = theme => ({
  root: {
    ...theme.overrides.MuiTypography.button,
    lineHeight: "1.4em", // Improve readability for multiline button.
    minWidth: theme.spacing(3),
    minHeight: 28,
    padding: `${theme.spacing(2)}px ${theme.spacing(8)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create(["background-color", "box-shadow"], {
      duration: theme.transitions.duration.shorter,
    }),
    "&:hover": {
      textDecoration: "none",

      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  },
  label: {
    width: "100%",
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
  text: {
    ...theme.overrides.MuiTypography.button,
    lineHeight: "1.4em", // Improve readability for multiline button.
    minWidth: 0,
    minHeight: 28,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    borderRadius: 4,
    background: "none",
    textTransform: "none",
    color: theme.palette.primary.main,
  },
  flat: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    minWidth: theme.spacing(15),
  },
  linkRadio: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.textColor,
    padding: "1.5rem 1rem",
    width: "100%",
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    fontSize: "0.9rem",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  dark: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  raised: {
    boxShadow: "0 0 12px 3px rgba(45,108,255,0.3)",
    "&:active": {
      boxShadow: "0 0 3px 1px rgba(45,108,255,0.3)",
    },
    "&$disabled": {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 3px 1px rgba(45,108,255,0.5)",

      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  },
  icon: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
    padding: 0,
    minHeight: "initial",
    minWidth: "initial",
    height: "2.8rem",
    width: "2.8rem",
    boxShadow: "0 0 12px 3px rgba(0,0,0,0.1)",

    "&:active": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",
    },
    "&$disabled": {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.primary.light,

      "& svg": {
        color: theme.palette.primary.borderColor,
      },
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",
      // Reset on mouse devices
      "@media (hover: none)": {},
      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },

    "& svg": {
      color: theme.palette.primary.main,
      padding: "0.1rem",
      minHeight: "initial",
      minWidth: "initial",
      height: "1.6rem",
      width: "1.6rem",
    },
  },
  iconClean: {
    background: "none",
    boxShadow: "none",
    padding: 0,
    minHeight: "initial",
    minWidth: "initial",
    height: "2.5rem",
    width: "2.5rem",

    "&:hover, &:focus": {
      "& svg": {
        color: theme.palette.primary.main,
      },
      // Reset on mouse devices
      "@media (hover: none)": {},
      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },

    "& svg": {
      color: theme.palette.primary.textColor,
      padding: "0.1rem",
      minHeight: "initial",
      minWidth: "initial",
      height: "2.5rem",
      width: "2.5rem",
    },
  },
  raisedSecondary: {
    boxShadow: "0 0 12px 3px rgba(0,0,0,0.1)",
    borderRadius: 4,

    "&:active": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",
    },
    "&$disabled": {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",

      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  },
  iconText: {
    boxShadow: "0 0 12px 3px rgba(0,0,0,0.1)",
    borderRadius: 4,
    padding: "7px 2rem 7px 1rem",
    textAlign: "center",

    "&:active": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",
    },
    "&$disabled": {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",

      "&$disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },

    "& svg": {
      padding: 0,
      marginRight: "1rem",
      minHeight: "initial",
      minWidth: "initial",
      height: "1.2rem",
      width: "1.2rem",
    },
  },
  error: {
    backgroundColor: theme.palette.primary.error,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  fullWidth: {
    width: "100%",
  },
})

class AppButton extends Component {
  render() {
    const {
      children: childrenProp,
      classes,
      className: classNameProp,
      color,
      disabled,
      disableFocusRipple,
      fullWidth,
      mini,
      size,
      variant,
      ...other
    } = this.props

    const action = variant === "action"
    const flat = variant === "flat"
    const text = variant === "text"
    const linkRadio = variant === "linkRadio"
    const iconText = variant === "iconText"
    const icon = variant === "icon"
    const iconClean = variant === "iconClean"
    const raised = !flat && !linkRadio && !text && !iconClean
    const primary = !action && !flat
    const className = classNames(
      classes.root,
      {
        [classes.raised]: raised && color === "default",
        [classes.raisedSecondary]: raised && color === "dark",
        [classes.flat]: flat,
        [classes.text]: text,
        [classes.linkRadio]: linkRadio,
        [classes.icon]: icon,
        [classes.iconClean]: iconClean,
        [classes.iconText]: iconText,
        [classes.secondary]: primary && color === "secondary",
        [classes.dark]: primary && color === "dark",
        [classes.disabled]: disabled,
        [classes.fullWidth]: fullWidth,
        [classes.error]: color === "red" || color === "error",
      },
      classNameProp
    )

    return (
      <ButtonBase className={className} disabled={disabled} {...other}>
        {this.props.children}
      </ButtonBase>
    )
  }
}

AppButton.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * Useful to extend the style applied to components.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(["default", "secondary", "dark"]),
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * The default value is a `button`.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href: PropTypes.string,
  /**
   * @ignore
   */
  type: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  variant: PropTypes.oneOf([
    "primary",
    "action",
    "flat",
    "linkRadio",
    "icon",
    "iconText",
    "text",
  ]),
}

AppButton.defaultProps = {
  color: "default",
  disabled: false,
  disableRipple: true,
  fullWidth: false,
  type: "button",
  variant: "primary",
}

export default withStyles(styles, { withTheme: true })(AppButton)
